<template>
  <ValidationObserver @submit="addAndUpdateChapterhandler">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        {{ modalHeading }}
      </template>
      <template v-slot>
        <UiSingleSelect
          v-model="chapter.subject_id"
          title="SUBJECT"
          :options="subjectList"
          label="title"
          class="flex-1"
          reduce="id"
          rules="required"
          :search-through-api="true"
          @search="getSubjects"
        />

        <InputFieldWrapper>
          <UiInput
            v-model="chapter.title"
            type="text"
            name="Chapter Title"
            title="Chapter Title"
            label="Chapter Title"
            placeholder="Chapter Title"
            class="flex-1"
            rules="required"
          />
          <UiInput
            v-model="chapter.description"
            type="text"
            name="Description"
            title="Description"
            class="flex-1"
            label="Description"
            placeholder="Description"
          />
        </InputFieldWrapper>

        <InputFieldWrapper>
          <UiInput
            v-model="chapter.number"
            type="text"
            name="Chapter Number"
            title="Chapter Number"
            class="flex-1"
            label="Chapter Number"
            placeholder="Chapter Number"
            rules="required|numeric"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
        <UIButton button="primary">
          <span v-if="isLoading">
            <Loader />
          </span>
          <span v-else>{{ saveBtnTitle }}</span>
        </UIButton>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import Loader from '@components/BaseComponent/Loader.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { mapActions } from 'vuex'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@/src/utils/filters'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  name: 'ChapterModal',
  components: {
    ValidationObserver,
    UIButton,
    UiModalContainer,
    UiInput,
    UiSingleSelect,
    Loader,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    edit: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      dashboard: 'dashboard',
      placeholder: 'placeholder',
      isLoading: false,
      chapter: {
        title: '',
        description: '',
        number: null,
        subject_id: '',
      },
      subjectList: [],
    }
  },
  computed: {
    saveBtnTitle() {
      return this.edit ? 'Update' : 'Create'
    },
    modalHeading() {
      return this.edit ? 'Edit Chapter' : 'Add Chapter'
    },
  },
  created() {
    if (this.edit) {
      this.chapter = {
        title: this.edit?.title,
        description: this.edit?.description,
        number: this.edit?.number,
        subject_id: this.edit?.subject_id,
      }
    }
  },
  mounted() {
    this.getSubjects()
  },
  methods: {
    getSubjects(query) {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.classSubjects(payload).then((res) => {
        this.subjectList = res?.data?.records || []
        if (this.edit) {
          let isExist = this.subjectList.some((el) => el.id === this.edit.subject_id)
          if (!isExist) this.subjectList.unshift(this.edit.subject)
        }
      })
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    addAndUpdateChapterhandler() {
      this.isLoading = true
      const data = {
        chapter: this.chapter,
      }
      if (this.edit) data.id = this.edit?.id
      this.addChapter(data)
        .then((res) => {
          this.$emit('toggle', data.chapter.subject_id)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    ...mapActions('subjects', ['classSubjects']),
    ...mapActions('chapter', ['addChapter']),
  },
}
</script>
