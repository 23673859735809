<template>
  <div>
    <TitleWrapper
      :filter-option="true"
      title="CHPTS"
      tooltip-title="CHPTS"
      :display-breadcrumb="true"
      :displayed-column-options="csvFilterOptions"
      :already-selected-displayed-column="filtersPayload.displayedColumns"
      :count="filtersCount"
      @cancel="cancelHanlder"
      @applyFilter="emitFiltersPayload($event, invalid)"
      @clear="clearFilters"
    >
      <template v-slot:filterItems>
        <InputFieldWrapper layout-mode="true">
          <UiInput
            v-model.trim="filtersPayload.title"
            class="flex-1"
            title="Title"
            type="text"
            placeholder="TITLE"
            :filter="FILTERS_LIST.TITLE"
            @emitQuery="updateQuery"
          />
          <UiInput
            v-model.trim="filtersPayload.number"
            class="flex-1"
            title="CHAPTER_N"
            type="text"
            rules="numeric"
            placeholder="CHAPTER_N"
            :filter="FILTERS_LIST.NUMBER"
            @emitQuery="updateQuery"
          />
          <UiSingleSelect
            v-model.trim="filtersPayload.subject_id"
            title="Select Subject"
            :options="subjectList"
            label="title"
            placeholder="Select Subject"
            class="flex-1"
            :class="$style.widthItems"
            reduce="id"
            :filter="FILTERS_LIST.SUBJECT"
            :search-through-api="true"
            @emitQuery="updateQuery"
            @search="getSubjectList"
          />
        </InputFieldWrapper>
      </template>
    </TitleWrapper>
  </div>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import TitleWrapper from '@src/components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { getCountFromObject, removeEmptyKeysFromObject } from '@utils/generalUtil'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import fileMixins from '@/src/mixins/file-mixins'
import filterMixins from '@/src/mixins/filter-mixins.js'
import { buildWhereQuery } from '@/src/utils/filters'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
export default {
  components: {
    UiInput,
    TitleWrapper,
    UiSingleSelect,
    InputFieldWrapper,
  },
  mixins: [fileMixins, filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      FILTER_KEYS,
      FILTERS_LIST,
      $where: {},
      queries: {},
      subjectList: [],
      appliedQueries: {},
      filtersCount: 0,
      title: 'title',
      filtersPayload: {
        title: '',
        number: '',
        subject_id: '',
        displayedColumns: [],
      },
    }
  },
  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      campus: (state) => state.layout.currentCampusScope || null,
    }),
  },

  watch: {
    campus: {
      handler() {
        this.clearFilters()
      },
    },
    currentSectionScope: {
      handler() {
        this.clearFilters()
        this.getSubjectList()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
    this.getSubjectList()
  },
  methods: {
    getSubjectList(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.classSubjects(payload).then((resp) => {
        this.subjectList = resp.data?.records || []
      })
    },
    getFiltersCount() {
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    ...mapActions('subjects', ['classSubjects']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
