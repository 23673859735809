<template>
  <ChapterFilters
    :csv-filter-options="displayedFilterOptions"
    :already-selected-displayed-columns="filtersData?.displayedColumns"
    @apply="applyFilters"
  />
  <div class="main-content minHieght80 rounded-t-lg">
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(chapterList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, requiredTableHeaders)"
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
          <TH v-permission="tableActionsPermissions" v-i18n="dashboard">Actions</TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(chapter, index) in chapterList" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Title', ['Title'])">
            {{ chapter.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Chapter Number', ['Chapter Number'])">
            {{ chapter.number || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Description')">
            {{ chapter.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Subject')">
            <span class="capitalize">
              {{ (chapter.subject && chapter.subject.title) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Actions', ['Actions'])"
            v-permission="tableActionsPermissions"
          >
            <TableAction
              :current-user="chapter"
              :idx="index"
              :action-list="actionList"
              @action="chapterAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(chapterCounts, filteredRecordLimit)"
      @filterRecord="filterRecords"
    />
  </div>
  <ChapterModal
    v-if="showModal"
    :modal="showModal"
    :edit="currentSelectedChapter"
    @toggle="toggleModal"
  />
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import Loader from '@components/BaseComponent/Loader.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import TableAction from '@/src/components/TableAction.vue'
import { mapActions, mapState } from 'vuex'
import generalUtil from '@src/mixins/general-mixins.js'
import ChapterModal from '@src/router/views/lesson-planner/chapter/chapter-modal.vue'
import ChapterFilters from './ChaptersFilter.vue'
import {
  removeEmptyKeysFromObject,
  objectDeepCopy,
  showColumnInDataTable,
} from '@src/utils/generalUtil.js'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import fileMixins from '@/src/mixins/file-mixins'
import GENERAL_CONSTANTS, { SCOPE_LEVELS } from '@src/constants/general-constants'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import chaptersData from '@/src/router/views/lesson-planner/chapter/Chapters.json'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  name: 'Chapter',
  components: {
    ChapterFilters,
    Loader,
    TableAction,
    ChapterModal,
    Pagination,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalUtil, fileMixins, scrollMixin],
  data() {
    return {
      attendanceTranslation: 'attendanceTranslation',
      tableActionsPermissions: {
        basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
        roles: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN, TENANT_ROLES.SECTION_TEACHER],
      },
      tableHeaders: chaptersData.tableHeaders,
      defaultSelectedColumns: chaptersData.defaultSelectedColumns,
      requiredTableHeaders: chaptersData.requiredTableHeaders,
      dashboard: 'dashboard',
      isLoading: false,
      actionList: [{ name: 'Edit' }],
      currentSelectedChapter: null,
      selectedSubject: '',
      chapterList: [],
      chapterCounts: '',
      showPagination: false,
      filteredRecordLimit: 10,
      filtersData: {},
      GENERAL_CONSTANTS,
    }
  },
  page: {
    title: 'Syllabus | Chapter',
    meta: [
      {
        name: 'description',
        content: 'Manage  Syllabus of each Chapter ',
      },
    ],
  },
  computed: {
    isCampusOrSectionChange() {
      return this.sectionId + this.campusId
    },
    ...mapState({
      tabRightBar: (state) => state.layout.tabRightNav,
      sectionId: (state) => state.layout.currentSectionScope.id,
      campusId: (state) => state.layout.currentCampusScope.id,
      showModal: (state) => state.layout.showModal,
    }),
  },
  watch: {
    isCampusOrSectionChange: {
      handler() {
        this.setRightBar()
        this.filterRecords()
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.fetchChapterStats()
      },
    },
    sectionId: {
      handler() {
        this.selectedSubject = ''
        this.chapterList = []
      },
    },
  },
  mounted() {
    this.filterRecords()
    this.setRightBar()
  },
  methods: {
    showColumnInDataTable,
    isEmpty,
    paginationRangeHandler,
    setRightBar(count = 0) {
      this.setRightbarContent({
        header: {
          title: 'Chapters',
          buttons: [
            {
              title: 'Add New Chapter',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal' },
              permissions: {
                roles: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.CAMPUS_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                ],
              },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: `TOTAL_CHAPTER`,
                value: count || GENERAL_CONSTANTS.EMPTY_RECORD,
                tooltipDescription: 'Total No of Chapters',
              },
            ],
          },
        ],
      })
    },

    chapterAction(action, current) {
      this.currentSelectedChapter = current
      switch (action) {
        case 'Edit':
          this.toggleModal()
          break
        case 'Delete':
          this.deleteChapter(this.currentSelectedChapter.id).then(() => {
            this.currentSelectedChapter = null
            this.filterRecords()
          })
          break
      }
    },
    toggleModal(payload = null) {
      this.setShowModal()
      if (payload) {
        this.filterRecords()
      }
      if (!this.showModal) this.currentSelectedChapter = null
    },
    filterRecords(range) {
      this.isLoading = true
      this.chapterList = []
      let paginationRange = paginationRangeHandler(range)
      let payload = {
        ...paginationRange,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      removeEmptyKeysFromObject(payload)
      this.getChapters(payload)
        .then((res) => {
          this.chapterList = res?.records
          this.chapterCounts = res?.meta?.total_records
          this.showPagination = this.chapterCounts > GENERAL_CONSTANTS.RECORD_LIMIT
        })
        .finally(() => {
          this.fetchChapterStats()
          this.isLoading = false
        })
    },
    fetchChapterStats(payload = { $where: this.filtersData?.$where }) {
      this.getChapterStats(payload).then((res) => {
        let count = res?.meta?.total_chapter_count
        this.setRightBar(count)
      })
    },
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecords()
    },
    ...mapActions('layout', ['setRightbarContent', 'setShowModal']),
    ...mapActions('chapter', ['getChapters', 'getChapterStats', 'deleteChapter']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
</style>

<style lang="scss" scoped>
.inputfield2 {
  min-width: 140px;
}
.inputfield {
  min-width: 200px;
}
.error {
  background: var(--bg-danger-light) !important;
}
.active {
  .step-counter {
    color: var(--white);
    background-color: var(--primary-green);
    border-color: var(--primary-green);
    box-shadow: 0 3px 14px var(--primary-green);
  }
  .step-info {
    color: var(--text-color);
  }
  .csv-table {
    td,
    th {
      min-width: 100px;
    }
  }
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
